"use client";
import { Divider } from '@nextui-org/react';
import { ReactNode, useState } from 'react';
import AnimateHeight from 'react-animate-height';

type Query = {
    id: number;
    question: string;
    answer: string | ReactNode; // Allow answer to be string or JSX
};

interface FaqProps {
    showTitle?: boolean;
    type?: string;
    queries?: Query[];
}

const Faq: React.FC<FaqProps> = ({
    showTitle = true,
    type = 'faq',
    queries = [
        {
            id: 1,
            question: 'What is Scam.SG?',
            answer: 'Scam.SG is a platform dedicated to verifying and promoting trustworthiness among businesses. We offer verification services, including the authenticated badge, to help consumers and businesses navigate the digital marketplace securely.',
        },
        {
            id: 2,
            question: 'How does Scam.SG work?',
            answer: 'Scam.SG works by conducting comprehensive background checks on businesses, ensuring they meet verification criteria. Verified businesses can display the authenticated badge on their websites, enhancing trust among consumers.',
        },
        {
            id: 3,
            question: 'Is Scam.SG only for businesses in Singapore?',
            answer: "While we initially focus on Singapore, our services are designed to benefit businesses and consumers globally. We aim to expand our coverage in the future.",
        },
        {
            id: 4,
            question: 'What is the authenticated badge?',
            answer: "The authenticated badge is a symbol of trust and credibility. It signifies that a business has undergone Scam.SG's verification process and meets the criteria for trustworthiness.",
        },
        {
            id: 5,
            question: 'How can my business get verified by Scam.SG?',
            answer: 'To get verified, businesses can register on Scam.SG, provide essential information, and undergo background checks. Choose a subscription tier that suits your needs to access the authenticated badge.',
        },
        {
            id: 6,
            question: 'What benefits do verified businesses enjoy?',
            answer: 'Verified businesses enjoy improved credibility, enhanced online reputation, and increased trust among consumers. This can lead to higher conversion rates and customer loyalty.',
        },
    ],
}) => {                             
    const [active, setActive] = useState<any>(0);
    return (
        <section className="py-7 lg:py-[60px]" itemScope itemType="https://schema.org/FAQPage">
            <div className="container">
                <div className="heading text-center">
                    <h6 className={`${showTitle ? '' : 'hidden'} ${type.toLowerCase() === 'modern-saas' ? '!text-secondary' : ''}`}>FAQs</h6>
                    <h4>
                        Frequently Asked <span className={type.toLowerCase() === 'restaurent' ? '!text-secondary' : ''}>Questions</span>
                    </h4>
                    <p className="mt-5 text-lg font-bold">Have questions? We’re here to help you.</p>
                </div>
                <div className="mx-auto bg-white px-4 rounded-lg">
                    {queries.map((faq: any, i: number) => {
                        return (
                            <div key={faq.id} className="bg-transparent items-center mb-6" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                <button
                                    type="button"
                                    className="relative !flex w-full items-center justify-between gap-2 py-2.5 text-lg font-bold text-black ltr:text-left rtl:text-right dark:text-white"
                                    onClick={() => setActive(active === i ? null : i)}
                                >
                                    <div itemProp="name">{faq.question}</div>
                                    <div
                                        className={`grid h-6 w-6 flex-shrink-0 place-content-center rounded-full border-2 transition ${
                                            active === i ? '!border-black !text-black dark:!border-white dark:!text-white' : ''
                                        }`}
                                    >
                                        {/* SVG icon code remains the same */}
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                className={active === i ? 'hidden' : ''}
                                                d="M6.09961 0.500977C6.65189 0.500977 7.09961 0.948692 7.09961 1.50098L7.09961 10.501C7.09961 11.0533 6.65189 11.501 6.09961 11.501H5.89961C5.34732 11.501 4.89961 11.0533 4.89961 10.501L4.89961 1.50098C4.89961 0.948692 5.34732 0.500977 5.89961 0.500977H6.09961Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M0.5 5.90039C0.5 5.34811 0.947715 4.90039 1.5 4.90039H10.5C11.0523 4.90039 11.5 5.34811 11.5 5.90039V6.10039C11.5 6.65268 11.0523 7.10039 10.5 7.10039H1.5C0.947715 7.10039 0.5 6.65268 0.5 6.10039V5.90039Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>
                                </button>
                                <AnimateHeight duration={600} height={active === i ? 'auto' : 0}>
                                    <div className="lg:w-4/5" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <p className="px-0 pb-5 pt-0 text-sm" itemProp="text">{faq.answer}</p>
                                    </div>
                                </AnimateHeight>
                                <Divider className="border-t border-gray-200 dark:border-gray-700" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default Faq;
